import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ParentRouteAccessService } from '../shared/parent-route-access.service';
import { PAGE } from './config';
import { AuthGuard, SuperAuthGuard } from './guard/auth.guard';

const routes: Routes = [
    { path: '', redirectTo: PAGE.default, pathMatch: 'full' },
    {
        path: 'dashboard',
        loadChildren: './module/dashboard/dashboard.module#DashboardModule',
        //canActivate: [ AuthGuard ]
    },
    {
        path: 'student',
        loadChildren: './module/student/student.module#StudentModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'lesson',
        loadChildren: './module/lesson/lesson.module#LessonModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'assignment',
        loadChildren: './module/assignment/assignment.module#AssignmentModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'report',
        loadChildren: './module/report/report.module#ReportModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'resource',
        loadChildren: './module/resource/resource.module#ResourceModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'resourceschool',
        loadChildren: './module/resourceschool/resourceschool.module#ResourceModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'user',
        loadChildren: './module/user/user.module#UserModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'cabinet',
        loadChildren: './module/cabinet/cabinet.module#CabinetModule',
        canActivate: [ParentRouteAccessService]
    },
    {
        path: 'super',
        loadChildren: './module/superAdmin/super-admin.module#SuperAdminModule',
        canActivate: [SuperAuthGuard]
    },
    {
        path: 'error',
        loadChildren: './module/error/error.module#ErrorModule',
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        redirectTo: PAGE.error,
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule { }
